<template>
  <div
    class="ma-0 row-bg-color custom-container-height"
    v-if="$store.getters.getWMSRECEIVINGTableProperties"
  >
    <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="$store.getters.getWMSRECEIVINGTableProperties"
      />
      <div
        class="min-w-250px max-w-500px pl-4 mt-8 font-weight-medium w-100 text-capitalize"
      >
        <v-text-field
          v-model="asnBarcode"
          label="Scan ASN"
          type="text"
          autofocus
          outlined
          clearable
          class="poppins"
          append-icon="mdi-barcode-scan"
          @click:append="handleASNScanClick"
          @keyup.enter="handleASNScanClick"
          :disabled="!selectedWarehouse"
        />
      </div>
      <DataTable v-if="selectedWarehouse" />
      <ReceivingDialog ref="receivingDialog" />
    </div>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DataTable from "@/own/components/warehouseManagement/receiving/DataTable.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ApiService from "@/core/services/api.service";
import {
  SET_SKUS,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/receiving.module";
import ReceivingDialog from "@/own/components/warehouseManagement/receiving/ReceivingDialog";
import Swal from "sweetalert2";

export default {
  name: "Receiving",
  components: { ReceivingDialog, DatatableDetail, DataTable },
  beforeMount() {
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
    this.$store.commit(SET_PAGE_LOADING, false);
  },
  mounted() {
    if (!this.selectedWarehouse) {
      setTimeout(() => {
        this.alertOnNoWarehouseSelected();
      }, 400);
    } else
      this.$store.dispatch(UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
    this.$store.commit(SET_PAGE_LOADING, false);
  },
  data: function () {
    return {
      asnBarcode: "",
    };
  },
  methods: {
    alertOnNoWarehouseSelected: function () {
      Swal.fire({
        title: `No warehouse selected`,
        icon: "warning",
        text: `Please select an warehouse to proceed further.`,
      });
    },
    handleASNScanClick() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/receiving/show", {
        asn_number: this.asnBarcode,
      })
        .then(({ data }) => {
          this.$store.commit(SET_SKUS, data.skus);
          this.$refs.receivingDialog.toggleModal(this.asnBarcode);
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  watch: {
    selectedWarehouse(newCount) {
      if (newCount) {
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store
          .dispatch(UPDATE_TABLE_DATA, {
            warehouse_id: newCount,
          })
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            // console.log(`We have ${newCount} fruits now, yay!`);
          });
      }
    },
  },
};
</script>

<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
