<template>
  <div class="bg-white h-100 custom-overflow rounded-lg pa-4 pt-0">
    <div
      class="h-100px bg-white position-sticky top-0 left-0 zindex-5 d-flex align-center justify-end flex-wrap px-4"
    >
      <v-text-field
        v-model="sku"
        class="max-w-250px"
        :label="
          $store.getters.getWMSRECEIVINGReceivingType === 3
            ? 'Scan carton'
            : 'Scan SKU'
        "
        clearable
        dense
        outlined
        hide-details
        hide-spin-buttons
        @keydown.enter="handleScan"
      />
      <div class="d-flex">
        <button class="btn btn--export-filter ml-3" @click="addTote">
          <span class="svg-icon">
            <v-icon size="18">mdi-plus-box-outline</v-icon>
          </span>
          Tote
        </button>
        <button class="btn btn--export-filter ml-3" @click="addLPN">
          <span class="svg-icon">
            <v-icon size="18">mdi-plus-box-outline</v-icon>
          </span>
          LPN
        </button>
      </div>
    </div>

    <v-data-table
      v-model="selected"
      dense
      :headers="skuHeaders"
      :items="$store.getters.getWMSRECEIVINGScannedSKUS"
      item-key="id"
      :items-per-page="-1"
      class="my-6 px-4"
      show-select
      checkbox-color="#7e04b7"
      hide-default-footer
      mobile-breakpoint="300"
      @dblclick:row="handleDoubleClickOnRow"
    >
      <template v-slot:item.sku="{ value }">
        {{ value }}
      </template>

      <template v-slot:item.photo="{ item }">
        <ShowImage :item="item" />
      </template>

      <template v-slot:item.qty="{ value }">
        {{ value }}
      </template>

      <template v-slot:item.flags="{ item }">
        <div class="d-flex flex-wrap max-w-250px">
          <span
            v-if="item.good_or_damage === 1"
            class="text-center badge badge-light-success font-size-sm my-2 mr-2 py-2 px-4"
          >
            Good
          </span>
          <span
            v-else
            class="text-center badge badge-light-warning font-size-sm my-2 mr-2 py-2 px-4"
          >
            Damaged
          </span>
          <span
            v-if="item.label_flag"
            class="text-center badge badge-light-success font-size-sm my-2 mr-2 py-2 px-4"
          >
            Labelled
          </span>
          <span
            v-if="item.bundle_flag"
            class="text-center badge badge-light-primary font-size-sm my-2 mr-2 py-2 px-4"
          >
            Bundled
          </span>
          <span
            v-if="item.qc_flag"
            class="text-center badge badge-light-info font-size-sm my-2 mr-2 py-2 px-4"
          >
            QC
          </span>
          <span
            v-if="item.assemble_flag"
            class="text-center badge badge-light-warning font-size-sm my-2 mr-2 py-2 px-4"
          >
            Assembled
          </span>
          <span
            v-if="item.exp_date"
            class="text-center badge badge-light-danger font-size-sm my-2 mr-2 py-2 px-4"
          >
            EXP: {{ item.exp_date }}
          </span>
        </div>
      </template>

      <template v-slot:item.tote="{ value }">
        {{ value }}
      </template>

      <template v-slot:item.lpn="{ value }">
        {{ value }}
      </template>
    </v-data-table>

    <SKUActionsDialog ref="skuDialog" />
    <ToteLPNDialog ref="toteLPNDialog" />
  </div>
</template>

<script>
import SKUActionsDialog from "@/own/components/warehouseManagement/receiving/SKUActionsDialog";
import Swal from "sweetalert2";
import ToteLPNDialog from "@/own/components/warehouseManagement/receiving/ToteLPNDialog";
import ShowImage from "@/own/components/warehouseManagement/receiving/ShowImage";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import { SET_SCANNED_SKUS } from "@/core/services/store/receiving.module";
export default {
  name: "ReceivingDataTable",
  props: ["asn_number"],
  components: { ShowImage, ToteLPNDialog, SKUActionsDialog },
  data: () => ({
    selected: [],
    sku: null,
  }),
  computed: {
    skuHeaders: function () {
      return [
        {
          text: "SKU",
          value: "sku",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Photo",
          value: "photo",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Quantity",
          align: "center",
          value: "qty",
          width: this.$vuetify.breakpoint.width > 1550 && 100,
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Flags",
          value: "flags",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Tote",
          value: "tote_id",
          width: this.$vuetify.breakpoint.width > 1550 && 150,
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "LPN",
          value: "lpn_id",
          width: this.$vuetify.breakpoint.width > 1550 && 150,
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
      ];
    },
    scanningType: function () {
      return this.$store.getters.getWMSRECEIVINGReceivingType === 3
        ? "carton"
        : "sku";
    },
  },
  methods: {
    handleScan() {
      this.$store.commit(SET_PAGE_LOADING, true);
      const receivedIndex = this.$store.getters.getWMSRECEIVINGSKUS.findIndex(
        (elem) => {
          if (elem.status === 2 && this.scanningType === "sku") {
            if (elem.sku === this.sku.trim()) return true;
          } else if (elem.status === 2 && this.scanningType === "carton") {
            if (elem.carton_number === this.sku.trim()) return true;
          }
        }
      );
      // console.log("receivedIndex", receivedIndex);
      if (receivedIndex > -1) {
        Swal.fire({
          title: "Warning",
          text: "This item already has been received",
          icon: "warning",
        });
        this.$store.commit(SET_PAGE_LOADING, false);
      } else {
        let sendData = {
          asn_number: this.asn_number,
          type: this.$store.getters.getWMSRECEIVINGBlindReceive
            ? "blind_receiving"
            : "receiving",
          sku: this.sku.trim(),
          scanning_type: this.scanningType,
        };
        ApiService.post(
          "/warehouse_management/receiving/validate_sku",
          sendData
        )
          .then(({ data }) => {
            if (data.success) {
              if (this.scanningType === "sku") {
                if (data.sku.is_allow_over_receiving) {
                  if (!this.$store.getters.getWMSRECEIVINGSimpleReceive)
                    this.$refs.skuDialog.toggleModal(data.sku);
                  else {
                    const sku =
                      this.$store.getters.getWMSRECEIVINGScannedSKUS.find(
                        (item) => item.sku === data.sku.sku
                      );

                    if (sku) {
                      sku.qty += 1;
                    } else {
                      let sendData = {
                        id: new Date().getTime(),
                        sku: data.sku.sku,
                        qty: 1,
                        description: data.sku.description,
                        photo: data.sku.photo,

                        label_flag: false,
                        bundle_flag: false,
                        qc_flag: false,
                        assemble_flag: false,
                        exp_flag: false,
                        exp_date: null,
                        good_or_damage: 1,
                        tote_id: "",
                        lpn_id: "",
                      };
                      // console.log("submitForm ssssss", sendData);
                      this.$store.commit(SET_SCANNED_SKUS, sendData);
                    }
                  }
                } else {
                  const skus =
                    this.$store.getters.getWMSRECEIVINGScannedSKUS.filter(
                      (item) => item.sku === data.sku.sku
                    );

                  let leftQty = data.sku.qty;
                  if (skus.length) {
                    skus.forEach((item) => {
                      leftQty -= item.qty;
                    });
                  }

                  // console.log("leftQty", leftQty);
                  if (leftQty !== 0) {
                    if (!this.$store.getters.getWMSRECEIVINGSimpleReceive)
                      this.$refs.skuDialog.toggleModal(data.sku);
                    else {
                      const sku =
                        this.$store.getters.getWMSRECEIVINGScannedSKUS.find(
                          (item) => item.sku === data.sku.sku
                        );

                      if (sku) {
                        sku.qty += 1;
                      } else {
                        let sendData = {
                          id: new Date().getTime(),
                          sku: data.sku.sku,
                          qty: 1,
                          description: data.sku.description,
                          photo: data.sku.photo,

                          label_flag: false,
                          bundle_flag: false,
                          qc_flag: false,
                          assemble_flag: false,
                          exp_flag: false,
                          exp_date: null,
                          good_or_damage: 1,
                          tote_id: "",
                          lpn_id: "",
                        };
                        // console.log("submitForm ssssss", sendData);
                        this.$store.commit(SET_SCANNED_SKUS, sendData);
                      }
                    }
                  } else
                    Swal.fire({
                      title: "Warning",
                      text: "You have reached the expected quantity",
                      icon: "warning",
                    });
                }
              } else if (this.scanningType === "carton") {
                const skuPayloadIds = data.skus.map((sku) => sku.id);
                const matchedIndexes =
                  this.$store.getters.getWMSRECEIVINGScannedSKUS.filter(
                    (elem) => skuPayloadIds.indexOf(elem.id) !== -1
                  );
                // console.log('matchedIndexes', matchedIndexes.length);

                if (matchedIndexes.length === 0) {
                  this.$store.commit(SET_SCANNED_SKUS, [
                    ...this.$store.getters.getWMSRECEIVINGScannedSKUS,
                    ...data.skus,
                  ]);
                } else
                  Swal.fire({
                    title: "Warning",
                    text: "You have already added this carton items",
                    icon: "warning",
                  });
              }
            } else
              Swal.fire({
                title: data.message,
                text: data.errors,
                icon: "error",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: "#F64E60FF",
              });
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            this.sku = "";
          });
      }
    },
    handleDoubleClickOnRow(_, { item }) {
      if (!this.$store.getters.getWMSRECEIVINGSimpleReceive)
        this.$refs.skuDialog.editSKU(item.id);
    },
    addTote() {
      if (this.selected.length === 0)
        Swal.fire({
          title: "Warning",
          text: `Please, select item!`,
          icon: "warning",
          showConfirmButton: true,
        });
      else this.$refs.toteLPNDialog.toggleModal(this.selected, "tote");
    },
    addLPN() {
      if (this.selected.length === 0)
        Swal.fire({
          title: "Warning",
          text: `Please, select item!`,
          icon: "warning",
          showConfirmButton: true,
        });
      else this.$refs.toteLPNDialog.toggleModal(this.selected, "LPN");
    },
  },
  watch: {
    asn_number(newValue) {
      if (newValue === null) {
        this.sku = null;
      }
    },
  },
};
</script>

<style scoped>
.custom-overflow {
  overflow-y: scroll;
  position: relative;
}

.custom-overflow::-webkit-scrollbar {
  width: 12px !important;
}
.custom-overflow::-webkit-scrollbar-thumb {
  border-radius: 12px !important;
}
</style>
